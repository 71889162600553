import actions from "./actions";

const initState = {
  userData: {}
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      let userData = {}
      userData.accessToken = action.payload.data.token
      return {
        ...state,
        userData
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
