import {
  COMMUNITIES
} from "../api.config";
import { getAPI, deleteAPI } from "library/helpers/api";
  
export function getCommunities(header, params) {
  return getAPI(COMMUNITIES, header, params);
}

export function deleteCommunity(header, params) {
  return deleteAPI(COMMUNITIES, header, params);
}
