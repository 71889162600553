import { combineReducers } from 'redux';
import App from 'modules/redux/app/reducer';
import Auth from 'modules/redux/auth/reducer';
import Community from 'modules/redux/community/reducer';
import loading from "modules/redux/loading/reducer";

export default combineReducers({
  Auth,
  App,
  Community,
  loading,
});
