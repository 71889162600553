import axios from 'axios';
import { BASE_URL } from "modules/config/api.config";

const CORS_HACK = "https://cors-anywhere.herokuapp.com/"

export const getAPI = async(path, header, params) => {
  try {
    const response = await axios.get(CORS_HACK + BASE_URL + path, 
      {
        headers: header
      },
      params
     );
    return response.data
  } catch (error) {
    return error.response.data 
    // return error.message
  }
}

export const postAPI = async(path, header, params) => {
  try {
    const response = await axios.post(CORS_HACK + BASE_URL + path, params,
    {
      headers: header
    }
    );
    return response.data
  } catch (error) {
    return error.response.data 
    // return error.message
  }
}

export const deleteAPI = async(path, header, id) => {
  try {
    const response = await axios.delete(CORS_HACK + BASE_URL + path + id,
    {
      headers: header
    },
    // params
    );
    return response.data
  } catch (error) {
    return error.response.data 
    // return error.message
  }
}