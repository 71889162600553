const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",

  LOGIN: "LOGIN",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  LOGOUT: "LOGOUT",

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  
  login: payload => ({
    type: actions.LOGIN_REQUEST,
    payload
  }),

  loginSuccess: payload => ({
    type: actions.LOGIN_SUCCESS,
    payload
  }),
  
  logout: () => ({
    type: actions.LOGOUT
  })
};

export default actions;
