import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import {
  getCommunities,
  deleteCommunity
} from "modules/config/api/community";
import message from "modules/components/Feedback/Message";

export function* getCommunitiesRequest() {
  yield takeEvery("GET_ALL_COMMUNITIES_REQUEST", function* (action) {
    const { params, callback, header } = action.payload;

    const response = yield call(getCommunities, header, params);
    
    if (response && response.status) {
      yield put({
        type: actions.GET_ALL_COMMUNITIES_SUCCESS,
        payload: response
      });
      yield call(callback, response.data)
    } else {
      yield put({ type: actions.GET_ALL_COMMUNITIES_ERROR });
      if (response.errorMessage) {
        yield call(message.error, response.errorMessage);
      }
      else {
        yield call(message.error, response);
      }
    }
  });
}

export function* deleteCommunityRequest() {
  yield takeEvery("DELETE_COMMUNITY_REQUEST", function* (action) {
    const { id, callback, header } = action.payload;
    const response = yield call(deleteCommunity, header, id);
    
    if (response && response.status) {
      yield put({
        type: actions.DELETE_COMMUNITY_SUCCESS,
        payload: response
      });
      yield call(callback, response.data)
    } else {
      yield put({ type: actions.DELETE_COMMUNITY_ERROR });
      if (response.errorMessage) {
        yield call(message.error, response.errorMessage);
      }
      else {
        yield call(message.error, response);
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCommunitiesRequest),
    fork(deleteCommunityRequest)
  ]);
}
