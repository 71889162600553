const actions = {

  GET_ALL_COMMUNITIES_REQUEST: "GET_ALL_COMMUNITIES_REQUEST",
  GET_ALL_COMMUNITIES_SUCCESS: "GET_ALL_COMMUNITIES_SUCCESS",
  GET_ALL_COMMUNITIES_ERROR: "GET_ALL_COMMUNITIES_ERROR",
  
  DELETE_COMMUNITY_REQUEST: "DELETE_COMMUNITY_REQUEST",
  DELETE_COMMUNITY_SUCCESS: "DELETE_COMMUNITY_SUCCESS",
  DELETE_COMMUNITY_ERROR: "DELETE_COMMUNITY_ERROR",
  
  getAllCommunities: payload => ({
    type: actions.GET_ALL_COMMUNITIES_REQUEST,
    payload
  }),

  deleteCommunity: payload => ({
    type: actions.DELETE_COMMUNITY_REQUEST,
    payload
  })
};

export default actions;
