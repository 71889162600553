import actions from "./actions";

const initState = {
  communities: {}
};

export default function communityReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_COMMUNITIES_SUCCESS:
      return initState;
    default:
      return state;
  }
}
