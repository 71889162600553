import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import { getToken, clearToken } from "library/helpers/utility";
import actions from "./actions";
import {
  login,
} from "modules/config/api/auth";
import message from "modules/components/Feedback/Message";

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* (action) {
    const { params } = action.payload;
    console.log(params);
    const header = {};
    const response = yield call(login, header, params);
    
    if (response && response.status) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: response
      });

    } else {
      yield put({ type: actions.LOGIN_ERROR });
      if (response.errorMessage) {
        yield call(message.error, response.errorMessage);
      }
      else {
        yield call(message.error, response);
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (action) {
    yield localStorage.setItem("accessToken", "Bearer " + action.payload.data.token);
})}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    yield call(history.push, "/");
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* (action) {
    const token = getToken().get("accessToken");
    if (token !== null) {
      const payload = {
        data:{
          token: token
        }
       }
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: payload
      });
    yield localStorage.setItem("accessToken", token);
    }
    else {
      history.push('/');
      // yield call(message.error, "Please Sign In first!");
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(checkAuthorization),
    fork(logout)
  ]);
}
