import { all } from "redux-saga/effects";
import authSagas from "modules/redux/auth/saga";
import communitySagas from "modules/redux/community/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    communitySagas()
  ]);
}
